.app {
    width: 100%;
    height: 100%;

    font-size: 16px;
    padding-bottom: 50px;
    overflow: hidden;

    display: flex;
    flex-direction: column;
}

.header {
    width: 100%;
    position: relative;
    height: 45px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-bottom: 1px solid #eee;
}

.header__title {
    color: #333;
    font-weight: bold;
    color: #f04155;
    font-size: 20px;
}

.header__button {
    position: absolute;
    top: 10px;
    font-size: 25px;
}

.header__button.right {
    right: 10px;
    color: #f04155;
}

.messages {
    overflow: auto;
    flex-grow: 1;
}

.messages__contact {
    display: block;
    position: relative;
    padding-right: 1em;
    padding-left: 70px;
}

.messages__contact__iconWrapper {
    position: absolute;
    top: 0;
    left: 15px;
    bottom: 0;

    display: flex;
    align-items: center;
}

.messages__contact__details {
    padding: 1em 0;
}

.messages__contact__name {
    width: 100%;
    font-size: 16px;
    position: relative;
    font-weight: bold;
    display: flex;
    flex-direction: column;
    color: #333;
}

.messages__contact__lastMessage {
    color: #333;
    font-size: 12px;
    margin-top: 5px;
}

.messages__contact__lastMessage__time {
    color: #999;
    margin-left: 10px;
}

/***************************************/

.thread {
    flex: 1;
    overflow: hidden;
    display: flex;
    flex-direction: column;
}

.thread__title {
    flex-grow: 0;
    flex-shrink: 0;
    padding: 10px 20px;
    font-size: 16px;
    text-align: center;
    font-weight: bold;
}

.thread__messagesWrapper {
    overflow: auto;
    flex: 1;
}

.thread__messages {
    padding: 0 10px 10px 10px;
}

/***************************************/

.message {
    position: relative;
    display: flex;
    flex-direction: row;
    flex-flow: nowrap;
    padding-top: 3px;
}

.message.in {
    flex-direction: row;
    margin-left: 35px;
}

.message.in.withProfile {
    margin-left: 0;
}

.message.out {
    flex-direction: row-reverse;
}

.message.in + .message.out,
.message.out + .message.in {
    margin-top: 3px;
}

.message__contactPhoto {
    margin-right: 5px;
    align-self: flex-end;
}

.message__content {
    font-size: 14px;
    display: inline-block;
}

.message__content__inner {
    border-radius: 20px;
    overflow: hidden;
}

.message.in .message__content__inner {
    border-bottom-left-radius: 5px;
}

.message.out .message__content__inner {
    border-bottom-right-radius: 5px;
}

.message__content__inner.text {
    padding: 8px 12px;
    max-width: 250px;
}

.in .message__content__inner {
    background-color: #ececec;
}

.out .message__content__inner {
    background-color: #f38c98;
}

.message__content__image {
    display: block;
    max-width: 250px;
    max-height: 250px;
}

.message__time {
    margin-top: 1px;
    margin-bottom: 3px;
    font-size: 12px;
    color: #666;
}

/***************************************/

.matches__offline {
    font-size: 26px;
    color: #666;
    text-align: center;
    padding-top: 30px;
}

.matches__offline__icon {
    font-size: 100px;
    margin-bottom: 10px;
    color: #ddd;
}

/***************************************/

.matches__empty {
    font-size: 26px;
    color: #666;
    text-align: center;
    padding-top: 30px;
}

.matches__empty__icon {
    font-size: 100px;
    margin-bottom: 10px;
    color: #ddd;
}
