.AppContacts {
    width: 100%;
    height: 100%;

    font-size: 16px;
    padding-bottom: 50px;
    overflow: hidden;
}

.AppContacts-list-scroller {
    overflow: auto;
    height: 100%;
}

.AppContacts-list {
}

.AppContacts-list-header {
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
}

.AppContacts-list-title {
    font-size: 20px;
}

.AppContacts-list-segments {
}

.AppContacts-list-segment {
    padding-bottom: 10px;
}

.AppContacts-list-segment-title {
    font-size: 14px;
    color: #999;
    padding: 0 10px 5px;
}

.AppContacts-list-segment-contacts {
    background-color: rgba(0, 0, 0, 0.03);
    border-radius: 20px;
}

.AppContacts-list-contact:not(:first-child) .AppContacts-list-contact-details {
    border-top: 1px solid #eee;
}

.AppContacts-list-contact {
    font-size: var(--base-font-size);
}

.AppContacts-list-contact-data {
    display: flex;
    position: relative;
    flex-wrap: nowrap;
    align-items: center;
}

.AppContacts-list-contact-icon-wrapper {
    display: flex;
    align-items: center;
    margin: 0 10px;
}

.AppContacts-list-contact-details {
    padding: 15px 0;
    margin-right: 10px;
    flex-grow: 1;
    position: relative;
}

.AppContacts-list-contact:last-child .AppContacts-list-contact-details {
    border-bottom: none;
}

.AppContacts-list-contact-name {
    width: 100%;
    font-size: 0.95em;
    position: relative;
    color: #333;
}

.AppContacts-list-contact-expand-icon {
    flex-shrink: 0;
    flex-grow: 0;
    font-size: 16px;
    color: #999;
    transform: rotate(90deg);
    margin-right: 30px;
}
