.AppEmail {
    width: 100%;
    height: 100%;

    font-size: var(--base-font-size);
    padding-bottom: 50px;
    overflow: hidden;
}

.AppEmail-inbox-scroller {
    overflow: auto;
    height: 100%;
}

.AppEmail-inbox {
    display: flex;
    flex-direction: column;
    height: 100%;
    width: 100%;
}

.AppEmail-inbox-header {
    padding: 20px 20px 10px;
    flex-shrink: 0;
    flex-grow: 0;
}

.AppEmail-inbox-search-bar {
    display: block;
    width: 100%;
    position: relative;
    margin-top: 5px;
}

.AppEmail-inbox-search-bar-input {
    width: 100%;
    height: 30px;
    line-height: 30px;
    background-color: #ddd;
    border: none;
    border-radius: 7px;
    margin: 0;
    padding: 0 15px;
}

.AppEmail-inbox-search-bar-input::-webkit-search-cancel-button {
    display: none;
}

.AppEmail-inbox-search-bar-clear {
    position: absolute;
    top: 0;
    right: 0;
    font-size: 16px;
    line-height: 14px;
    padding: 7px 10px;
    cursor: pointer;
    display: none;
}

.AppEmail-inbox-search-bar-clear.visible {
    display: block;
}

.AppEmail-inbox-title {
    font-size: 24px;
}

.AppEmail-inbox-subtitle {
    margin-top: 5px;
    font-size: 14px;
    color: #333;
    text-align: center;
}

.AppEmail-inbox-no-results {
    margin-top: 10px;
    font-size: 14px;
    color: #333;
    text-align: center;
}

.AppEmail-inbox-emails {
    border-top: 1px solid #eee;
    padding: 0 20px;
}

.AppEmail-inbox-email-link {
}

.AppEmail-inbox-email {
    border-bottom: 1px solid #eee;

    position: relative;
    padding: 15px 0 10px 0;
}

.AppEmail-inbox-email-date,
.AppEmail-inbox-email-subject,
.AppEmail-inbox-email-body {
    color: #666;
    font-size: 0.75em;
}

.AppEmail-inbox-email-date {
    position: absolute;
    top: 15px;
    right: 0;
}

.AppEmail-inbox-email-open-icon {
    margin-left: 4px;
}

.AppEmail-inbox-email-from {
    width: 100%;
    font-size: 0.85em;
    position: relative;
    color: #333;
}

.AppEmail-inbox-email-count {
    font-size: 12px;
    margin-left: 5px;
}

.AppEmail-inbox-email-subject {
    margin-top: 6px;
    color: #333;
}

.AppEmail-inbox-email-body {
    margin-top: 6px;
    color: #999;
}

.AppEmail-inbox-email-body strong {
    font-weight: normal;
}

.AppEmail-inbox-email-body > p,
.AppEmail-inbox-email-body > h1,
.AppEmail-inbox-email-body > h2 {
    margin: 0;
    font-size: 0.95em;
    font-weight: normal;
    color: #333;
}

/***************************************/

.AppEmail-thread {
    position: relative;
    width: 100%;
    height: 100%;
    padding-top: 50px;
    display: flex;
}

.AppEmail-thread-top-bar {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;

    background-color: white;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    flex-shrink: 0;
    /* padding: 10px 20px; */
}

.AppEmail-thread-back-button {
    display: inline-block;
    font-size: 22px;
    color: #333;
    align-self: center;
    padding: 10px;
}

.AppEmail-thread-content {
    width: 100%;
    padding: 15px;
    position: relative;
    overflow: auto;
}

.AppEmail-thread-subject {
    font-weight: bold;
    font-size: 1.125em;
    line-height: 1.3;
}

.AppEmail-email {
    position: relative;
    padding-top: 20px;
    border-top: 1px solid #ddd;
}

.AppEmail-email:first-child {
    border-top: none;
}

.AppEmail-email-date {
    position: absolute;
    top: 20px;
    right: 0;
    color: #666;
    font-size: 0.75em;
}

.AppEmail-email-from-name {
    font-size: 1em;
}

.AppEmail-email-to {
    font-size: 0.875em;
    margin-top: 2px;
    color: #666;
}

.AppEmail-email-details-button {
    margin-left: 5px;
    font-size: 0.88em;
    line-height: 0.875em;
    transition: transform 0.1s linear;
    display: inline-block;
}

.AppEmail-email-details-button.open {
    transform: rotate(180deg);
}

.AppEmail-email-details {
    display: none;
    font-size: 0.875em;
    border: 1px solid #ddd;
    border-radius: 0.3125em;
    margin-top: 0.3125em;
    padding: 0.3125em;
    grid-template-columns: auto auto;
    grid-gap: 0.625em;
}

.AppEmail-email-details.visible {
    display: grid;
}

.AppEmail-email-details-item-title {
    color: #666;
}

.AppEmail-email-details-item-value {
    color: #333;
}

.AppEmail-email-body {
    font-size: 1em;
    line-height: 1.5;
}

.AppEmail-email-attachment {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    border: 1px solid #eee;
    border-radius: 5px;
    padding: 10px;
    margin-bottom: 10px;
}

.AppEmail-email-attachment-type {
    width: 40px;
    height: 40px;
    background-color: #008ef2;
    line-height: 40px;
    text-align: center;
    color: white;
    border-radius: 5px;
    margin-right: 10px;
    flex-shrink: 0;
}

.AppEmail-email-attachment-details {
    flex-grow: 1;
}

.AppEmail-email-attachment-name {
    color: #333;
    font-size: 1em;
}

.AppEmail-email-attachment-size {
    color: #999;
    font-size: 0.875em;
    margin-top: 2px;
}

.AppEmail-email-attachment-download {
    font-size: 25px;
    color: #008ef2;
    flex-shrink: 0;
    display: block;
    height: 25px;
}

/***************************************/

.AppEmail-attachment-downloading {
    width: 100%;
    font-size: 16px;
    text-align: center;
    padding-top: 100px;
}

.AppEmail-attachment-password-protector {
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    padding: 20px;
}

.AppEmail-attachment-password-text {
    display: inline-block;
    font-size: 16px;
}

.AppEmail-attachment-password-protector
    .AppEmail-attachment-password-textbox-form {
    margin-top: 10px;
}

.AppEmail-attachment-password-textbox {
    display: inline-block;
    width: 100%;
    height: 40px;
    line-height: 40px;
    background-color: #ddd;
    border: none;
    border-radius: 25px;
    margin: 0;
    padding: 0 30px;
}

@keyframes shake {
    from,
    to {
        transform: translate3d(0, 0, 0);
    }

    10%,
    50%,
    90% {
        transform: translate3d(-10px, 0, 0);
    }

    30%,
    70% {
        transform: translate3d(10px, 0, 0);
    }
}

.AppEmail-attachment-password-textbox.error {
    animation-name: shake;
    animation-duration: 0.3s;
    animation-iteration-count: 1;
}

.AppEmail-attachment-password-submit {
    font-size: 16px;
    background-color: #4db5ff;
    color: #333;
    padding: 5px 20px;
    border-radius: 20px;
    border: none;
    display: inline-block;
    margin-top: 10px;
}

.AppEmail-attachment-image-wrapper {
    width: 100%;
    height: 100%;
    overflow: auto;
}

.AppEmail-attachment-image {
    width: 100%;
    height: 100%;

    display: block;
    object-fit: contain;
}

.AppEmail-email-from-name {
    max-width: calc(100% - 7.2em);
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}
