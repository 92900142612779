.player {
    flex-shrink: 0;
}

.player :global(.rhap_container) {
    padding: 10px 9px;
}

.player :global(.rhap_play-pause-button) {
    margin-left: 0;
}

.player :global(.rhap_controls-section) {
    flex: 0;
    margin-right: 0;
}

.player :global(.rhap_time) {
    color: var(--chat-incoming-text);
    margin-left: 8px;
}

.playButton {
    display: inline-block;
    vertical-align: top;
    font-size: 40px;
    line-height: 40px;
    width: 40px;
    height: 40px;
}
