.message {
    position: relative;
    border-radius: 7.5px;
    background-color: white;
    margin-bottom: 2px;
    padding: 3px;
    max-width: 90%;

    box-shadow: 0 1px 0.5px rgba(var(--shadow-rgb), 0.13);
    font-size: var(--base-font-size);
}

.message.in {
    align-self: flex-start;
}

.message.out {
    align-self: flex-end;
    background-color: var(--outgoing-background);
}

.message.extraSpace {
    margin-top: 12px;
}

.message.system {
    align-self: center;
    background-color: var(--chat-system-background);
    color: var(--chat-system-text);
    padding: 10px 16px;
    text-align: center;
}

.message.audio {
    min-width: 200px;
}

.message.error {
    margin-bottom: 18px;
}

.text {
    padding: 2px 9px;
    /*white-space: pre-wrap;*/
    word-wrap: break-word;
}

.text.deleted {
    color: #999;
    font-style: italic;
}

.text.emoji {
    font-size: 1.875em;
}

.text__contents {
    display: inline;
}

.text__contents > p {
    margin-bottom: 0;
}

.text__contents > p:first-child {
    margin-top: 0;
}

.text__contents a {
    color: #039be5;
}

.image {
    max-width: 100%;
    max-height: 330px;
    display: block;
    margin-bottom: 2px;
    border-radius: 6px;
}

.text__spacer {
    width: 74px;
    display: inline-block;
    vertical-align: top;
}

.emoji .text__spacer {
    width: 54px;
}

.out .text__spacer {
    width: 80px;
}

.time {
    position: absolute;
    bottom: 2px;
    right: 6px;
    font-size: 0.6875em;
    color: var(--bubble-meta);
}

.status__icon {
    margin-left: 3px;
    vertical-align: text-top;
}

.status__icon.error {
    color: var(--icon-error);
}

.notDelivered {
    position: absolute;
    bottom: -16px;
    right: 3px;
    font-size: 0.75em;
    color: var(--icon-error);
}
