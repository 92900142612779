.root {
    display: grid;
    place-content: center;
    grid-template-rows: 1fr 4fr 1fr;
    padding: 20px;
    height: 100%;
}

.title {
    font-size: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.inputContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.textbox {
    display: inline-block;
    line-height: 2;
    background-color: #ddd;
    border: none;
    border-radius: 25px;
    margin: 0;
    padding: 0 50px 0 30px;
    margin-top: 20px;
    margin-bottom: 20px;
    font-size: 24px;
    text-align: center;
}

.textboxForm {
    text-align: center;
    width: 85%;
}

.cancel {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-end;
    margin-bottom: 60px;
}

.textbox.animated {
    animation-name: shake;
    animation-duration: 0.3s;
    animation-iteration-count: 1;
}

@keyframes shake {
    from,
    to {
        transform: translate3d(0, 0, 0);
    }

    10%,
    50%,
    90% {
        transform: translate3d(-10px, 0, 0);
    }

    30%,
    70% {
        transform: translate3d(10px, 0, 0);
    }
}
