.PhoneFooter {
    width: 100%;
    height: 42px;
    position: absolute;
    z-index: var(--zindex-phone-footer);
    bottom: 0;
    left: 0;
    right: 0;
    display: flex;
    justify-content: space-evenly;
    background: white;
}

.home {
    background: linear-gradient(transparent 0%, rgba(0, 0, 0, 0.2) 100%);
}

.PhoneFooter-button {
    font-size: 18px;
    width: 1em;
    height: 1em;
    line-height: 1;
    padding: 10px;
    box-sizing: content-box;
    color: #666;
    transition: color 0.1s;
}

.home .PhoneFooter-button {
    color: white;
    text-shadow: 0px 1px 2px rgba(0, 0, 0, 0.3);
}

.PhoneFooter-button.exit {
}

.PhoneFooter-button-icon {
    display: inline-block;
    vertical-align: top;
}

.PhoneFooter-home-button {
    padding: 10px 20px;
    display: inline-block;
}

.PhoneFooter-home-button:before {
    content: "";
    display: inline-block;
    box-sizing: border-box;
    width: 18px;
    height: 18px;
    border-radius: 6px;
    border: 2px solid #666;
    transition: border-color 0.1s;
}

.home .PhoneFooter-home-button:before {
    border-color: white;
    box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.3);
}
