.button {
    font-size: 15px;
    padding: 10px 20px;
    border-radius: 10px;
    border: none;
    display: inline-block;
    transition: opacity 0.3s;
    cursor: pointer;
    background-color: #eee;
    color: #333;
    overflow: hidden;
    position: relative;
}

.button.primary {
    background-color: var(--color-bg-primary);
}

.button.secondary {
    background-color: var(--color-bg-secondary);
}

.button.minor {
    background-color: white;
    border: 1px solid #666;
}

.button.small {
    font-size: 13px;
    line-height: 1;
    padding: 8px 15px;
    border-radius: 7px;
}

.button.fullWidth {
    display: block;
    width: 100%;
}

.button.displayFont {
    font-family: var(--font-family-display);
}

.button.disabled {
    opacity: 0.5;
    pointer-events: none;
}

.button.shine {
    animation: grow 3.5s infinite 3s;
}

.button.shine:after {
    content: "";
    top: 0;
    left: 0;
    transform: translateX(100%);
    /*transform: translateX(50%);*/
    width: 100%;
    height: 100%;
    position: absolute;
    z-index: 1;
    animation: slide 3.5s infinite 3s;
    pointer-events: none;
    background: linear-gradient(
        45deg,
        rgba(255, 255, 255, 0) 20%,
        rgba(255, 255, 255, 0.8) 50%,
        rgba(125, 185, 232, 0) 80%
    );
}

.button.loading > .content {
    opacity: 0;
}

.spinnerWrapper {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

@keyframes slide {
    0% {
        transform: translateX(-100%);
    }
    100% {
        transform: translateX(300%);
    }
}

@keyframes grow {
    13% {
        transform: scale(1);
    }
    25% {
        transform: scale(1.02);
    }
    37% {
        transform: scale(1);
    }
}
