.ContactInfo-info {
    overflow: hidden;
    max-height: 5.5em;
    transition: max-height 0.3s;
    font-size: var(--base-font-size);
}

.ContactInfo-info.hidden {
    max-height: 0;
}

.ContactInfo-details {
    text-align: center;
    font-size: 0.9em;
    color: #1dbf73;
    padding: 10px;
}

.ContactInfo-details-item {
    margin-top: 5px;
}

.ContactInfo-details-item:first-child {
    margin-top: 0;
}

.ContactInfo-icons {
    text-align: center;
    padding: 0 10px 10px;
}

.ContactInfo-icon {
    display: inline-block;
    font-size: 26px;
    line-height: 1;
    vertical-align: top;
}

.ContactInfo-icon.call {
    color: #1dbf73;
}
