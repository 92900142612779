.Phone {
    box-sizing: content-box;
    width: 100%;
    height: 100%;
    padding-left: 80%;
    display: flex;
    flex-direction: row;

    transition: transform 0.3s ease-in-out;
    transform: translate3d(-44.44444444444%, 0, 0);
}

.Phone.menuOpen {
    transform: translate3d(0, 0, 0);
}
