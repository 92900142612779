.battery {
    position: relative;
    height: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: right;
}

.percent {
    font-size: 14px;
}

.icon {
    display: inline-block;
    position: relative;
    width: 25px;
    height: 12px;
    border: 1px solid rgba(255, 255, 255, 0.9);
    border-radius: 3px;
    margin: 0 5px 0 2px;
    transform: scale(1);
    transition: transform 0.2s ease-in-out;
}

.dark .icon {
    border-color: #000;
}

.icon:after {
    content: "";
    width: 2px;
    height: 5px;
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
    background-color: rgba(255, 255, 255, 0.9);
    position: absolute;
    right: -4px;
    top: 3px;
}

.animate .icon {
    transform: scale(1.1);
}

.dark .icon:after {
    background-color: rgba(0, 0, 0, 0.9);
}

.bar {
    background-color: white;
    height: 100%;
    min-width: 3px;
    position: absolute;
    top: 0;
    left: 0;
    border: 1px solid rgba(0, 0, 0, 0.9);
    border-radius: 2px;
}

.animate .bar {
    transition: width 1s ease-in-out;
    background-color: #5ff39d;
}

.dark .bar {
    background-color: rgba(0, 0, 0, 0.9);
    border-color: #fff;
}

.bar.low {
    background-color: #e95646;
}

.fullColors .bar.high {
    background-color: #5ff39d;
}

.fullColors .bar.medium {
    background-color: #f7ce46;
}

.unlimited .bar {
    background-color: #5fdcf3 !important;
}

.chargingIcon {
    position: absolute;
    top: -50%;
    left: 50%;
    height: 200%;
    transform: translateX(-50%);
    width: auto;
}

.chargingIcon__path {
    fill: #fff;
    stroke: #000;
}

.dark .chargingIcon__path {
    fill: #000;
    stroke: #fff;
}

.unlimited .chargingIcon__path {
    fill: #f3f15f !important;
}

.dark.unlimited .chargingIcon__path {
    stroke: rgba(0, 0, 0, 0.6);
}
