.modal {
    position: absolute;
    top: 0;
    left: 0;
    z-index: var(--zindex-modal);
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.3);
    backdrop-filter: blur(10px);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.modal.entranceEffect {
    opacity: 0;
    transition: opacity 0.1s;
}

.modal.entranceEffect.entered {
    opacity: 1;
}

.contents {
    width: 95%;
    max-width: 600px;
    max-height: 90%;
    background-color: white;
    border-radius: 20px;
    padding: 30px;
    text-align: center;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.entranceEffect .contents {
    transform: scale(0.6);
    transition: transform 0.3s;
}

.entranceEffect.entered .contents {
    transform: scale(1);
}

.close {
    position: absolute;
    top: 0;
    right: 0;
    padding: 8px;
    font-size: 20px;
    z-index: 10;
    cursor: pointer;
    color: #333;
}

.title {
    font-size: 17px;
    font-family: var(--font-family-display);
    flex-shrink: 0;
}

.text {
    font-size: 15px;
    margin-top: 1em;
    line-height: 1.3;
}

.text.left {
    width: 100%;
    text-align: left;
}

.textbox {
    width: 100%;
    font-size: 15px;
    line-height: 1.3;
    background-color: #ddd;
    border: none;
    border-radius: 15px;
    margin: 0;
    padding: 15px;
    margin-top: 10px;
    vertical-align: top;
}

.button {
    margin-top: 15px;
}
