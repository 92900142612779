.locker {
    display: block;
    position: absolute;
    z-index: 10;
    height: calc(100% - 20px);
    top: 20px;
    right: 0;
    left: 0;
    padding-bottom: 42px;

    background-color: white;
    opacity: 1;
    transition: visibility 0s linear 0s, opacity 1s linear;
}

.locker:global(.unlocked) {
    opacity: 0;
    pointer-events: none;
    visibility: hidden;
    transition: visibility 0s linear 1s, opacity 1s linear;
}

/********************************************/

.password {
    background-color: white;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    padding: 80px 20px 20px 20px;
    height: 100%;
}

.password__title {
    font-size: 20px;
    text-align: center;
}

.password__hint {
    font-size: 14px;
    color: #999;
    text-align: center;
    margin-top: 20px;
}

.password__textbox {
    display: inline-block;
    line-height: 2;
    background-color: #ddd;
    border: none;
    border-radius: 25px;
    margin: 0;
    padding: 0 50px 0 30px;
    font-size: 24px;
    text-align: center;
}

.password__textbox:global(.animated) {
    animation-name: shake;
    animation-duration: 0.3s;
    animation-iteration-count: 1;
}

.password__textbox_form {
    text-align: center;
    width: 80%;
    margin-top: 20px;
}

.password__submit {
    font-size: 16px;
    background-color: #4db5ff;
    color: #333;
    padding: 5px 20px;
    border-radius: 20px;
    border: none;
    display: inline-block;
    margin-top: 10px;
}

@keyframes shake {
    from,
    to {
        transform: translate3d(0, 0, 0);
    }

    10%,
    50%,
    90% {
        transform: translate3d(-10px, 0, 0);
    }

    30%,
    70% {
        transform: translate3d(10px, 0, 0);
    }
}

/********************************************/

.usernamePassword__logo {
    width: 30%;
    height: auto;
    border-radius: 10px;
}

/********************************************/

.converter {
    padding: 50px 20px;
    height: 100%;
    background-color: white;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
}

.converter__input {
    display: inline-block;
    width: 80%;
    line-height: 2;
    background-color: #ddd;
    border: none;
    border-radius: 25px;
    margin: 0;
    padding: 0 30px;
    font-size: 24px;
    text-align: center;
}

/* Chrome, Safari, Edge, Opera */
.converter__input::-webkit-outer-spin-button,
.converter__input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

/* Firefox */
.converter__input {
    -moz-appearance: textfield;
}

.converter__headline {
    display: block;
    font-size: 20px;
    margin-bottom: 1em;
}

.converter__result {
    font-size: 20px;
    margin-top: 1em;
    line-height: 1.3;
}

.converter__result__value {
    font-weight: bold;
    font-size: 1.5em;
    margin: 0.3em 0;
}

/********************************************/

.tilePuzzle {
    background-color: white;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    padding: 80px 10px 20px;
    height: 100%;
}

.tilePuzzle__title {
    font-size: 20px;
    text-align: center;
    margin-bottom: 20px;
}
