.SideMenu {
    height: 100%;
    width: 80%;
    margin-left: -80%;
    background: radial-gradient(
        circle at 75%,
        rgba(14, 19, 48, 0.8) 0%,
        rgba(14, 19, 48, 0.9) 40%
    );
}

.SideMenu-items {
    padding-top: 20px;
}

.SideMenu-items.bottom {
    position: absolute;
    bottom: 0;
    padding-bottom: 10px;
}

.SideMenuItem {
    padding: 15px 30px;
    color: #fff;
}

.SideMenuItem.disabled {
    pointer-events: none;
    opacity: 0.5;
}

.SideMenuItem-icon {
    width: 1em;
    height: 1em;
    vertical-align: top;
    margin-right: 15px;
    opacity: 0.8;
}

.CoinsIcon {
    width: 1em;
    height: 1em;
    display: inline-block;
    vertical-align: top;
    margin-right: 0.2em;
}

@media screen and (max-height: 460px) {
    .SideMenu-items.bottom {
        display: none;
    }
}
