.root {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 20px;
    height: 100%;
}

.title {
    font-size: 20px;
    text-align: center;
}

.hint {
    font-size: 14px;
    color: #999;
    text-align: center;
    margin-top: 10px;
}

.root:global(.darkMode) .hint {
    color: #fff;
}

.value {
    overflow: hidden;
    padding: 40px 0 20px;
    line-height: 1;
    white-space: nowrap;
}

.value:global(.animated) {
    animation-name: shake;
    animation-duration: 0.3s;
    animation-iteration-count: 1;
}

.valueDigit {
    display: inline-block;
    vertical-align: top;
    width: 40px;
    text-align: center;
}

.valueDigit:before {
    content: "";
    display: inline-block;
    width: 10px;
    height: 10px;
    border: 1px solid #333;
    border-radius: 10px;
    vertical-align: top;

    background-color: transparent;
    transition: background-color 0.2s;
}

.root:global(.darkMode) .valueDigit:before {
    border-color: white;
}

.valueDigit:global(.full):before {
    background-color: #333;
}

.root:global(.darkMode) .valueDigit:global(.full):before {
    background-color: white;
}

.keyboard {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    text-align: center;
    margin-top: 20px;
}

.key {
    font-size: 35px;
    width: 50px;
    height: 50px;
    line-height: 54px;
    margin: 0 20px 30px;
    border-radius: 35px;
    background-color: rgba(0, 0, 0, 0.05);
}

.root:global(.darkMode) .key {
    background-color: rgba(255, 255, 255, 0.1);
}

.erase {
    font-size: 30px;
    line-height: 52px;
    text-indent: -3px;
}

@keyframes shake {
    from,
    to {
        transform: translate3d(0, 0, 0);
    }

    10%,
    50%,
    90% {
        transform: translate3d(-10px, 0, 0);
    }

    30%,
    70% {
        transform: translate3d(10px, 0, 0);
    }
}
