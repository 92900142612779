.LoginScreen {
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    overflow: auto;
    width: 100%;
    height: 100%;
    padding: 20px 20px 50px;
}

.LoginScreen-textboxForm {
    margin-top: 10px;
}

.LoginScreen-textbox {
    display: inline-block;
    width: 100%;
    height: 40px;
    line-height: 40px;
    background-color: rgba(255, 255, 255, 0.8);
    border: none;
    border-radius: 25px;
    margin: 0;
    padding: 0 50px 0 30px;
    flex-shrink: 0;
}

.LoginScreen-textbox.username {
    margin-top: 10px;
}

.LoginScreen-textbox.username.disabled {
    opacity: 0.6;
}

.LoginScreen-submit-button {
    font-size: 16px;
    background-color: rgba(0, 0, 0, 0.2);
    color: #333;
    padding: 10px 20px;
    border-radius: 20px;
    border: none;
    display: inline-block;
    margin-top: 10px;
    width: 100%;
    flex-shrink: 0;
}

.LoginScreen-submit-button.disabled {
    pointer-events: none;
    opacity: 0.7;
}

@keyframes LoginScreen-shake {
    from,
    to {
        transform: translate3d(0, 0, 0);
    }

    10%,
    50%,
    90% {
        transform: translate3d(-10px, 0, 0);
    }

    30%,
    70% {
        transform: translate3d(10px, 0, 0);
    }
}

.LoginScreen-submit-button.error {
    animation-name: LoginScreen-shake;
    animation-duration: 0.3s;
    animation-iteration-count: 1;
}

.LoginScreen-error {
    color: red;
    margin-top: 10px;
    visibility: hidden;
    flex-shrink: 0;
}

.LoginScreen-error.visible {
    visibility: visible;
}
