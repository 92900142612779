.tutorial {
    --transition-duration: 0.3s;

    position: absolute;
    z-index: var(--zindex-tutorial);
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
}

.tutorial.hidden {
    visibility: hidden;
    pointer-events: none;
}

.tutorial.noCircle {
    background-color: rgba(0, 0, 0, 0.6);
}

.circle {
    position: absolute;
    z-index: 1;
    border-radius: 30px;
    background: transparent;
    box-shadow: 0 0 0 1000px rgba(0, 0, 0, 0.6);
    transition: top var(--transition-duration) ease-in-out,
        left var(--transition-duration) ease-in-out,
        width var(--transition-duration) ease-in-out,
        height var(--transition-duration) ease-in-out;
}

.circle.glow {
    box-shadow: 0 0 3px #ffe205, 0 0 7px #ffe205, 0 0 20px #edd205,
        0 0 0 1000px rgba(0, 0, 0, 0.6);
}

.circle__tapIcon {
    position: absolute;
    width: 50px;
    height: auto;
    top: 50%;
    left: 50%;
    animation: hover 2s infinite;
    cursor: pointer;
}

.tooltip {
    position: absolute;
    z-index: 2;
    width: 95%;
    background-color: white;
    border-radius: 10px;
    padding: 20px;
    right: 2.5%;
    top: 0;
    font-size: 16px;
    color: #333;
    text-align: left;
    transition: transform var(--transition-duration) ease-in-out;
}

.firstStep .tooltip {
    transition: none;
}

.tooltip__button {
    width: 100%;
    margin-top: 10px;
    text-align: right;
    color: #1dbf73;
    padding: 10px 0 10px 20px;
    display: inline-block;
    cursor: pointer;
}

@keyframes hover {
    from,
    to {
        transform: translate3d(0, 0, 0);
    }

    50% {
        transform: translate3d(-5px, -5px, 0);
    }
}
