.calculator {
    background-color: white;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 20px;
    height: 100%;
}

.calculator__value {
    color: #333;
    font-size: 35px;
    text-align: left;
    width: 100%;
    flex-grow: 1;
    padding: 20px 10px;
    line-height: 1;
    position: relative;
}

.calculator__value__inner {
    position: absolute;
    top: 20px;
    bottom: 20px;
    left: 10px;
    right: 10px;
    width: calc(100% - 20px);
    overflow: hidden;
    overflow-wrap: break-word;
    display: block;
    border: 0;
    padding: 0;
}

.calculator__keyboard {
    display: grid;
    width: 100%;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    text-align: center;
}

.calculator__key {
    font-size: 25px;
    line-height: 25px;
    padding: 10px;
    background-color: #f2f2f2;
    border-radius: 20px;
    margin: 10px;
}

.calculator__key:global(.c) {
    color: #ff8a00;
}

.calculator__key:global(.op) {
    color: #88c100;
}

.calculator__key:global(.eq) {
    background-color: #88c100;
    color: white;
    font-size: 40px;
}
