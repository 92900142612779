.AppNews {
    width: 100%;
    height: 100%;

    font-size: 16px;
    overflow: hidden;
    background-color: #fbfaff;
}

.AppNews-scroller {
    overflow: auto;
    height: 100%;
    padding-bottom: 50px;
}

.AppNews-index {
    padding: 0 5%;
}

.AppNews-index-title {
    font-size: 24px;
    line-height: 3;
    font-weight: bold;
    color: #474c72;
}

.NewsIndexItem {
    display: block;
}

.NewsIndexItem.featured {
    background-color: #fff;
    border-radius: 20px;
}

.NewsIndexItem.list {
    display: flex;
    flex-direction: row;
    margin-top: 25px;
}

.NewsIndexItem-image {
    width: 100%;
    height: 180px;
    border-radius: 20px;
    background-size: cover;
    background-position: center center;
}

.list > .NewsIndexItem-image {
    height: 80px;
    width: 100px;
    flex-shrink: 0;
    flex-grow: 0;
}

.list > .NewsIndexItem-contents {
    padding-left: 16px;
}

.NewsIndexItem-title {
    font-size: 18px;
    line-height: 1.4;
    font-weight: bold;
    color: #474c72;
}

.featured .NewsIndexItem-title {
    padding: 5%;
}

.NewsIndexItem-unread {
    font-size: 13px;
    color: white;
    background-color: red;
    border-radius: 10px;
    margin-right: 5px;
    line-height: 20px;
    display: inline-block;
    padding: 0 5px;
    vertical-align: text-top;
}

.list .NewsIndexItem-title {
    font-size: 16px;
}

.NewsIndexItem-subtitle {
    font-size: 14px;
    color: #3f4572;
    margin-top: -2.5%;
}

.featured .NewsIndexItem-subtitle {
    padding: 0 5% 5%;
}

.list .NewsIndexItem-subtitle {
    margin-top: 3px;
}

/*****************************/

.NewsItem-contents {
    padding: 0 5%;
    font-size: var(--base-font-size);
}

.NewsItem-image {
    width: 90%;
    margin: 5% 5% 0 5%;
    height: auto;
    border-radius: 20px;
}

.NewsItem-title {
    font-size: 20px;
    line-height: 1.3;
    font-weight: bold;
    margin-top: 0.7em;
    color: #474c72;
}

.NewsItem-subtitle {
    font-size: 1.1em;
    color: #3f4572;
    margin-top: 10px;
}

.NewsItem-body {
    line-height: 1.3;
    color: #474c72;
}

.NewsItem-body a {
    color: #474c72;
    text-decoration: underline;
}
