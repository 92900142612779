.ChatWindow {
    display: flex;
    flex-direction: row;
    width: 100%;
    height: 100%;
}

.ChatWindow.mobile {
    width: 200%;
    transform: translate3d(0, 0, 0);
    transition: transform 0.3s;
}

.ChatWindow.mobile.chat {
    transform: translate3d(-50%, 0, 0);
}

.ChatWindow-left {
    height: 100%;
    width: 35%;
    flex-grow: 0;
    flex-shrink: 0;
}

.ChatWindow-right {
    width: 100%;
    height: 100%;
    flex-grow: 1;
}

.mobile .ChatWindow-left,
.mobile .ChatWindow-right {
    width: 50%;
    flex-grow: 0;
    flex-shrink: 0;
}
