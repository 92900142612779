.root {
}

.label {
    margin-bottom: 5px;
}

.dropzone {
    position: relative;
    border: 2px dashed #aaa;
    border-radius: 5px;
    padding: 5px;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 14px;
}

.thumbnail {
    width: 100%;
    height: 100%;
    object-fit: contain;
}

.delete {
    position: absolute;
    top: -12px;
    right: -12px;
    width: 24px;
    height: 24px;
    line-height: 24px;
    font-size: 16px;
    border-radius: 20px;
    text-align: center;
    background-color: #000;
    color: #fff;
    cursor: pointer;
    z-index: 2;
}

.uploading {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 10;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #fff;
    font-size: 14px;
}
