.header {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;

    width: 100%;
    height: 20px;
    background-color: #0e0e0e;
}

.header__left,
.header__right {
    display: flex;
    align-items: center;

    position: absolute;
    top: 0;
    height: 100%;
    line-height: 14px;

    color: white;
    font-size: 14px;
}

.header__left {
    left: 15px;
}

.header__right {
    right: 15px;
}

.icon {
    margin-right: 4px;
}

.icon.wifi {
    margin-right: 4px;
    font-size: 13px;
}

.icon.noSignal {
    margin-right: 4px;
    font-size: 12px;
}
