.wrapper {
    width: unset !important;
    height: unset !important;
    overflow: visible !important;
}

.content {
    width: unset !important;
    height: unset !important;
}
