.studio {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    background-color: #fff;
}

.studio__editArea {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: row;
    overflow: hidden;
}

.studio__bar {
    flex-grow: 1;
    flex-shrink: 0;
}

.studio__bar__title {
    flex-grow: 1;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.studio__bar__icon {
    color: #fff;
}

.studio__bar__saving {
    padding-right: 1em;
}

.studio__bottomNav {
    flex-grow: 0;
    flex-shrink: 0;
}

.studio__loading {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
}

.studio__loading__text {
    font-size: 20px;
    margin-top: 10px;
}

.screenSizeWarning {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: center;
    align-items: center;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 100;

    padding: 7px 10px;
    color: #9f6000;
    background-color: #feefb3;
}

.screenSizeWarning__text {
    flex-shrink: 1;
}

.screenSizeWarning__dismiss {
    flex-shrink: 0;
    flex-grow: 0;
    font-size: 1.5em;
    margin-left: 5px;
    cursor: pointer;
}
