.AppStory15Game {
    overflow: hidden;
    padding-bottom: 42px;
    background-color: #181818;
}

.AppStory15Game-screen {
    width: 100%;
    height: 100%;
    position: relative;
}

.AppStory15Game-screen.vertical-center {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.AppStory15Game-game-over {
    background-size: cover;
    background-position: center center;
}

.AppStory15Game-game-over-restart {
    position: absolute;
    bottom: 100px;
    left: 50%;
    transform: translateX(-50%);
}

.AppStory15Game-level1-image {
    width: 100%;
    height: auto;
}

.AppStory15Game-answer-field {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    margin-top: 20px;
    width: 60%;
    position: relative;
}

.AppStory15Game-image-main {
    display: block;
    max-width: 90%;
    max-height: 90%;
    object-fit: contain;
}

.AppStory15Game-image-main.float {
    animation: AppStory15Game-float 6s ease-in-out infinite;
}

.AppStory15Game-image-main.spin {
    animation: AppStory15Game-spin 180s linear infinite;
}

.AppStory15Game-level-intro {
    position: absolute;
    top: 0;
    left: 0;
    background-color: black;
    opacity: 1;
    transition: opacity 1s;
}

.AppStory15Game-level-intro.exiting {
    opacity: 0;
    pointer-events: none;
}

.AppStory15Game-level-intro-text {
    color: white;
    animation: AppStory15Game-intro-out 4s linear 1;
}

@keyframes AppStory15Game-intro-out {
    0% {
        transform: scale(0.1);
        opacity: 1;
    }
    100% {
        transform: scale(10);
        opacity: 0;
    }
}

.AppStory15Game .react-transform-component {
    overflow: visible;
}

/**********************/

.AppStory15Game-level-done {
    position: absolute;
    top: 0;
    left: 0;
    background-color: black;
    transition: opacity 1s;
    opacity: 0;
}

.AppStory15Game-level-done.entered {
    opacity: 1;
}

.AppStory15Game-level-done-text {
    color: white;
}

/**********************/

.AppStory15Game-level2 {
    background: linear-gradient(72deg, #a1c4fd, #c2e9fb);
}

.AppStory15Game-level2-text {
    font-size: 20px;
    line-height: 1.3;
    font-weight: bold;
    text-align: center;
    color: rgba(0, 0, 0, 0.8);
}

/**********************/

.AppStory15Game-level3 {
    background-color: #e9f4e9;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
}

.AppStory15Game-level3-image {
    display: block;
    max-width: 100%;
    max-height: 100%;
    object-fit: contain;
}

.AppStory15Game-level3 .AppStory15Game-answer-field {
    position: absolute;
    bottom: 10%;
}

.AppStory15Game-level3 .AppStory15Game-answer-field-textbox {
    background-color: rgba(0, 0, 0, 0.8);
    color: white;
}

/**********************/

.AppStory15Game-level4-image {
    display: block;
    max-width: 100%;
    max-height: 100%;
    object-fit: contain;
}

.AppStory15Game-level4 .AppStory15Game-answer-field {
    position: absolute;
    bottom: 5%;
}

/**********************/

.AppStory15Game-level5 {
    background: linear-gradient(72deg, #ffecd2, #fcb69f);
}

/**********************/

.AppStory15Game-level6 {
    background: linear-gradient(72deg, #aa076b, #61045f);
}

.AppStory15Game-level6 .AppStory15Game-image-main {
    width: 90%;
    height: auto;
}

/**********************/

.AppStory15Game-level7-intro {
    font-size: 18px;
    line-height: 1.2;
    color: white;
    padding: 20px;
    text-align: center;
    overflow: auto;
}

.AppStory15Game-level7-start-button {
    background-color: white;
    color: black;
    padding: 10px 20px;
    border-radius: 10px;
    display: inline-block;
}

.AppStory15Game-level7-video {
    object-fit: cover;
    width: 100%;
    height: 100%;
}

.AppStory15Game-level7 .AppStory15Game-answer-field {
    position: absolute;
    bottom: 5%;
}

/**********************/

.AppStory15Game-answer-field.errorAnimation {
    animation: shake 0.3s;
}

.AppStory15Game-answer-field-textbox {
    width: 100%;
    display: inline-block;
    border-radius: 10px;
    background-color: rgba(255, 255, 255, 0.6);
    border: none;
    font-size: 20px;
    font-weight: bold;
    line-height: 1;
    padding: 10px 50px 10px 20px;
}

.AppStory15Game-answer-field-submit {
    font-size: 29px;
    background-color: rgba(0, 0, 0, 0.4);
    color: white;
    border-radius: 10px;
    height: 100%;
    width: 50px;
    line-height: 47px;
    text-align: center;
    position: absolute;
    right: 0;
    top: 0;
    opacity: 0;
    transition: opacity 0.2s;
    pointer-events: none;
}

.AppStory15Game-answer-field-submit.visible {
    opacity: 1;
    pointer-events: all;
}

@keyframes AppStory15Game-float {
    0% {
        /*box-shadow: 0 5px 15px 0px rgba(0, 0, 0, 0.6);*/
        transform: translateY(0px);
    }
    50% {
        /*box-shadow: 0 25px 15px 0px rgba(0, 0, 0, 0.2);*/
        transform: translateY(-10px);
    }
    100% {
        /*box-shadow: 0 5px 15px 0px rgba(0, 0, 0, 0.6);*/
        transform: translateY(0px);
    }
}

@keyframes AppStory15Game-spin {
    100% {
        transform: rotate(360deg);
    }
}
