.HomeScreen {
    height: 100%;
    padding-bottom: 50px;

    background-size: cover;
    background-position: top center;
}

.HomeScreen-position-bottom {
    display: flex;
    align-items: flex-end;
}

.HomeScreen-apps {
    width: 100%;

    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    padding: 0 10px;
    gap: 0 10px;
}

.HomeScreen-app {
    text-align: center;
    text-decoration: none;
    padding: 20px 0;
    font-size: var(--base-font-size);
}

.HomeScreen-app-icon-wrapper {
    position: relative;
    display: inline-block;
    width: 50px;
    height: 50px;
}

.HomeScreen-app-notif-counter {
    position: absolute;
    top: -5px;
    right: -7px;
    background-color: #fe0000;
    color: white;
    font-size: 14px;
    line-height: 20px;
    border-radius: 12px;
    border: 1px solid white;
    padding: 0 4px;
    min-width: 22px;
    text-align: center;
}

.HomeScreen-app-icon {
    width: 100%;
    height: 100%;
    border-radius: 18px;
}

.HomeScreen-app-name {
    margin-top: 0.1875em;
    font-size: 0.875em;
    color: white;
    text-shadow: 0px 0px 2px #333;
    text-decoration: none;
}

@media screen and (max-width: 320px) {
    .HomeScreen-apps {
        grid-template-columns: 1fr 1fr 1fr;
    }
}
