.spinner {
    display: inline-block;
    width: 30px;
    height: 30px;
    position: absolute;
    top: 50%;
    left: 50%;
    margin: -15px 0 0 -15px;
}

.spinner__inner {
    box-sizing: border-box;
    display: block;
    position: absolute;
    width: 24px;
    height: 24px;
    margin: 3px;
    border: 3px solid #666;
    border-radius: 50%;
    animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
    border-color: #666 transparent transparent transparent;
}

.spinner__inner:nth-child(1) {
    animation-delay: -0.45s;
}

.spinner__inner:nth-child(2) {
    animation-delay: -0.3s;
}

.spinner__inner:nth-child(3) {
    animation-delay: -0.15s;
}

@keyframes lds-ring {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}
