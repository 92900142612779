.AppStreaming {
    width: 100%;
    height: 100%;

    font-size: 16px;
    padding-bottom: 50px;
    overflow: hidden;
    position: relative;
}

.AppStreaming-index-sections {
    overflow: auto;
    height: 100%;
    padding: 10px;
}

.AppStreaming-section {
    width: 100%;
    margin-bottom: 20px;
}

.AppStreaming-section:last-child {
    margin-bottom: 0;
}

.AppStreaming-section-name {
    font-weight: bold;
    padding-bottom: 4px;
    font-size: 16px;
    color: #1e1e1e;
}

.AppStreaming-section-titles {
    width: 100%;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    overflow-x: auto;
}

.AppStreaming-section-title {
    height: 130px;
    width: 90px;
    background-size: cover;
    background-position: center center;
    position: relative;
    margin-right: 10px;
    flex-shrink: 0;
    flex-grow: 0;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    padding: 10px;
    border-radius: 5px;
}

.AppStreaming-section-title-name {
    font-size: 14px;
    color: #fff;
    text-shadow: 0 0 3px black;
    text-align: center;
    font-weight: bold;
}

/**********************************************/

.AppStreaming-title {
    height: 100%;
}

.AppStreaming-title-bg {
    width: 100%;
    height: 250px;
    background-size: cover;
    background-position: top center;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    padding-bottom: 10px;
}

.AppStreaming-title-name {
    font-size: 30px;
    font-weight: bold;
    color: #fff;
    text-shadow: 0 0 3px black;
    padding-left: 20px;
}

.AppStreaming-title-top-bar {
    position: absolute;
    z-index: 11;
    top: 0;
    left: 0;
    right: 0;

    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
}

.AppStreaming-title-back-button {
    display: inline-block;
    font-size: 22px;
    color: white;
    text-shadow: 0 0 3px black;
    align-self: center;
    padding: 10px 10px 10px 20px;
}

.AppStreaming-title-details {
    padding: 20px;
}

.AppStreaming-title-details-info-detail {
    font-size: 12px;
    color: #999;
    margin-right: 10px;
}

.AppStreaming-title-details-info-detail.hd {
    font-size: 10px;
    color: white;
    background-color: #999;
    border-radius: 4px;
    padding: 2px 3px;
    font-weight: bold;
}

.AppStreaming-title-friends {
    margin-top: 10px;
    font-size: 14px;
    color: #333;
}

.AppStreaming-title-details-offline {
    margin-top: 40px;
    text-align: center;
}

.AppStreaming-title-details-offline-title {
    font-size: 18px;
    font-weight: bold;
    color: #666;
}

.AppStreaming-title-details-offline-text {
    font-size: 14px;
    color: #999;
    margin-top: 8px;
}
