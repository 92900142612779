.root {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 20px;
    height: 100%;
}

.title {
    font-size: 20px;
    text-align: center;
}

.root:global(.darkMode) .hint {
    color: #fff;
}

.keyboard {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    text-align: center;
    margin-top: 20px;
}

.keyboard.animated {
    animation-name: shake;
    animation-duration: 0.3s;
    animation-iteration-count: 1;
}

.key {
    padding: 20px;
    margin: 10px;
}

.key:before {
    content: "";
    display: inline-block;
    box-sizing: border-box;
    vertical-align: top;
    height: 25px;
    width: 25px;
    border-radius: 25px;
    border: 1px solid #333;
    transition: background-color 0.1s;
}

.key:global(.on):before {
    background-color: #333;
}

.key:global(.darkMode):before {
    border-color: rgba(255, 255, 255, 0.8);
}

.key:global(.darkMode):global(.on):before {
    background-color: rgba(255, 255, 255, 0.8);
}

@keyframes shake {
    from,
    to {
        transform: translate3d(0, 0, 0);
    }

    10%,
    50%,
    90% {
        transform: translate3d(-10px, 0, 0);
    }

    30%,
    70% {
        transform: translate3d(10px, 0, 0);
    }
}
