.AppAuthenticator {
}

.AppAuthenticator-title {
    height: 40px;
    line-height: 40px;
    color: white;
    background-color: #1a274c;
    text-align: center;
}

.AppAuthenticator-entry {
    padding: 20px 30px;
    border-bottom: 1px solid #eee;
    position: relative;
}

.AppAuthenticator-entry-code {
    font-size: 30px;
    color: #333;
}

.AppAuthenticator-entry-name {
    margin-top: 5px;
    font-size: 14px;
    color: #666;
}

.AppAuthenticator-entry-time {
    position: absolute;
    bottom: 20px;
    right: 30px;
    font-size: 14px;
    color: #666;
}
