.AppNotes {
    width: 100%;
    height: 100%;

    font-size: var(--base-font-size);
    padding-bottom: 50px;
    overflow: hidden;
}

.AppNotes-index-scroller {
    overflow: auto;
    height: 100%;
}

.AppNotes-index {
}

.AppNotes-index-header {
    height: 200px;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
}

.AppNotes-index-title {
    font-size: 1.875em;
}

.AppNotes-index-subtitle {
    margin-top: 0.375rem;
    font-size: 0.875em;
    color: #333;
}

.AppNotes-index-notes {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 10px 10px;
    padding: 0 5px;
}

.AppNotes-index-note-link {
    overflow: hidden;
}

.AppNotes-index-note {
    background-color: rgba(0, 0, 0, 0.03);
    border-radius: 15px;
    height: 5.3125em;
    position: relative;
    padding: 15px;
}

.AppNotes-index-note-contents {
    width: 100%;
    height: 100%;
    overflow: hidden;
}

.AppNotes-index-note-title {
    width: 100%;
    font-size: 0.875em;
    position: relative;
    color: #333;
    font-weight: bold;
}

.AppNotes-index-note-text {
    color: #666;
    font-size: 0.75em;
}

.AppNotes-index-note-title + .AppNotes-index-note-text {
    margin-top: 0.625em;
}

.AppNotes-index-note-text > p {
    margin: 0;
}

.AppNotes-index-note-text > p > a {
    color: #666;
    pointer-events: none;
    text-decoration: none;
}

/***************************************/

.AppNotes-note {
    position: relative;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
}

.AppNotes-note-top-bar {
    height: 48px;
    flex-shrink: 0;
    flex-grow: 0;

    background-color: white;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-items: center;
    /* padding: 10px 20px; */
}

.AppNotes-note-back-button {
    display: inline-block;
    font-size: 22px;
    color: #333;
    align-self: center;
    height: 100%;
    padding: 10px 10px 10px 20px;
}

.AppNotes-note-content {
    height: 100%;
    padding: 20px;
    overflow: auto;
}

.AppNotes-note-title {
    font-weight: bold;
    font-size: 1em;
}

.AppNotes-note-text {
    font-size: 0.875em;
    line-height: 1.5;
}

.AppNotes-note-text > p:first-child {
    margin-top: 0;
}

.AppNotes-note-text > p:last-child {
    margin-bottom: 0;
}
