.app {
    height: 100%;
    width: 100%;
    padding-bottom: 50px;
}

.index {
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    background-color: #f4f4f4;
    position: relative;
}

.title {
    height: 50px;
    line-height: 50px;
    font-size: 18px;
    font-weight: bold;
    text-align: center;
    border-bottom: 1px solid #dadada;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
    background-color: white;
}

.index__contents {
    width: 100%;
    height: 100%;
    overflow: auto;
    padding-bottom: 20px;
}

.index__devices {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 30px;
    padding: 30px;
}

.index__help {
    font-size: 15px;
    text-align: center;
    line-height: 1.3;
}

.index__help__link {
    text-decoration: underline;
    color: blue;
}

.deviceTile {
    background-color: white;
    height: 175px;
    border-radius: 10px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
    padding: 25px 10px 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    color: #141414;
}

.deviceTile__icon {
    height: 40px;
    filter: brightness(0%);
}

.deviceTile__name {
    font-weight: bold;
    font-size: 14px;
    margin-top: 1em;
    text-align: center;
}

.deviceTile__status {
    font-size: 12px;
    margin-top: 5px;
    color: #999;
}

.deviceTile__toggle {
    margin-top: 14px;
}

.deviceTile__toggle:global(.react-toggle--checked)
    :global(.react-toggle-track) {
    background-color: #f9d700 !important;
}

.deviceTile__toggle:global(.react-toggle--checked)
    :global(.react-toggle-thumb) {
    border-color: #f9d700 !important;
}

.deviceTile__toggle :global(.react-toggle-thumb) {
    box-shadow: none !important;
}

.device {
}

.camera {
    position: relative;
    width: 100%;
    height: 0;
    padding-top: 56.25%;
}

.camera__wrapper {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    overflow: hidden;
    background-color: black;
}

.camera__zoomableWrapper {
    width: unset !important;
    height: unset !important;
    overflow: visible !important;
    position: relative;
    z-index: 2;
}

.camera__zoomableContent {
    width: unset !important;
    height: unset !important;
}

.camera__video {
    display: block;
    width: 100%;
    height: 100%;
    position: relative;
}

.camera__connecting {
    position: absolute;
    width: 100%;
    top: 50%;
    left: 0;
    text-align: center;
    margin-top: -0.5em;
    font-size: 14px;
    line-height: 1;
    color: #fff;
    z-index: 1;
}

:global(.lightsOff) .camera__video {
    opacity: 0.05;
}

.camera__instructions {
    margin-top: 10px;
    font-size: 14px;
    text-align: center;
}

.keypad {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;

    opacity: 0;
    visibility: hidden;
    transition: visibility 0s linear 0.1s, opacity 0.1s linear;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 20px;

    color: white;
    text-shadow: 0 0 2px black;
    background-color: rgba(0, 0, 0, 0.5);
    backdrop-filter: blur(10px);
}

.keypad:global(.visible) {
    opacity: 1;
    visibility: visible;
    transition: visibility 0s linear 0s, opacity 0.1s linear;
}

@media screen and (max-width: 300px) {
    .index__devices {
        grid-gap: 10px;
        padding: 10px;
    }
    .deviceTile {
        height: 150px;
        padding: 10px 5px;
    }
}
