.root {
    width: 100%;
    height: 100%;

    font-size: 16px;
    padding-bottom: 50px;
    overflow: hidden;

    display: flex;
    flex-direction: column;
}

.header {
    flex-grow: 0;
    flex-shrink: 0;

    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
}

.header__title {
    font-size: 18px;
}

.footer {
    flex-grow: 0;
    flex-shrink: 0;

    height: 30px;
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: center;
}

.footer__item {
    color: #333;
    font-size: 14px;
    padding: 10px 5px 3px;
    cursor: pointer;
}

.footer__item.active {
    color: #1dbf73;
    font-weight: bold;
    border-bottom: 2px solid #1dbf73;
}

.history {
    overflow: auto;
    height: 100%;
    border-radius: 20px;
}

.history__empty {
    font-size: 20px;
    font-weight: bold;
    margin: 20px 0;
    text-align: center;
    color: #333;
}

.history__segment {
    padding-bottom: 10px;
}

.history__segment:last-child {
    padding-bottom: 0;
}

.history__segment__title {
    font-size: 14px;
    color: #999;
    padding: 0 10px 5px;
}

.history__segment__calls {
    background-color: rgba(0, 0, 0, 0.03);
    border-radius: 20px;
}

.call {
}

.call__data {
    width: 100%;
    display: flex;
    position: relative;
    flex-wrap: nowrap;
}

.call__iconWrapper {
    display: flex;
    align-items: center;
    margin: 0 10px;
}

.call__details {
    border-top: 1px solid #eee;
    padding: 15px 0;
    margin-right: 20px;
    flex-grow: 1;
    position: relative;
    font-size: var(--base-font-size);
}

.call:first-child .call__details {
    border-top: none;
}

.call__name {
    width: 100%;
    font-size: 0.95em;
    position: relative;
    color: #333;
}

.call__time {
    display: inline-block;
    position: absolute;
    right: 0;
    bottom: 15px;
    font-size: 12px;
}

.call__recording {
    position: absolute;
    right: 0;
    top: 0;
    font-size: 16px;
}

.call__recording__icon:global(.icon-stop) {
    display: none;
}

.call__recording:global(.playing) :global(.icon-stop) {
    display: inline-block;
}

.call__recording:global(.playing) :global(.icon-play) {
    display: none;
}

.call__type {
    color: #666;
    font-size: 12px;
    line-height: 12px;
    margin-top: 3px;
}

.call__direction {
    font-size: 8px;
    padding: 2px 0;
    display: inline;
    vertical-align: top;
}

.call__direction:global(.icon-left) {
    color: #88c100;
}
.call__direction:global(.icon-right) {
    color: #ff8a00;
}

.dialer {
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    overflow: auto;
}

.dialer__number {
    font-size: 40px;
    height: 100%;
    max-height: 3em;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.dialer__name {
    font-size: 16px;
    opacity: 0.8;
    line-height: 14px;
    text-align: center;
    margin-top: 0.5em;
}

.dialer__pad {
    flex-grow: 0;
    flex-shrink: 0;
}

.dialer__pad__grid {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    text-align: center;
}

.dialer__pad__key {
    font-size: 30px;
    width: 50px;
    height: 50px;
    line-height: 54px;
    margin: 15px;
    border-radius: 35px;
}

.activeCall {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    text-align: center;
    z-index: 10;
    color: #fff;

    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    padding: 50px 0;

    background: linear-gradient(
        30deg,
        rgba(43, 19, 60, 1) 0%,
        rgba(136, 18, 18, 1) 50%,
        rgba(113, 81, 35, 1) 100%
    );
}

.activeCall__title {
    font-size: 30px;
    font-weight: bold;
}

.callButton {
    display: inline-block;
    flex-shrink: 0;
    flex-grow: 0;
    vertical-align: top;
    font-size: 30px;
    line-height: 1;
    padding: 12px 12px 11px;
    border-radius: 30px;
    color: #fff;
}

.callButton.start {
    background-color: #1dbf73;
}

.callButton.end {
    background-color: #ef3248;
}

.callButton.end:before {
    transform: rotate(135deg) translate(1px, 0px);
}

@media screen and (max-height: 570px) {
    .dialer__number {
        font-size: 30px;
    }

    .dialer__pad__key {
        font-size: 25px;
        width: 40px;
        height: 40px;
        line-height: 44px;
        margin-top: 12px;
        margin-bottom: 12px;
    }
}
