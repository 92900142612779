.container {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px;
    text-align: center;
    background-color: rgba(255, 255, 255, 0.05);
}

.title {
    font-size: 20px;
    line-height: 1.1;
    line-height: 1.1;
    text-align: center;
    color: #fff;
    margin-bottom: 10px;
}

.requiredProgressText {
    line-height: 1.1;
    margin-bottom: 10px;
    font-size: 16px;
    font-style: italic;
    color: lightblue;
}

.question,
.error {
    font-size: 14px;
}

.question {
    margin: 0 0 13px;
    color: #fff;
}

.error {
    margin: 0 0 13px;
    color: #f4364c;
}

.textbox {
    width: 100%;
    border: 1px solid #999;
    border-radius: 7px;
    padding: 10px;
    background: rgba(255, 255, 255, 0.85);
    border: 0;
}

.textbox[disabled] {
    opacity: 0.5;
}

.submitButton {
    position: relative;
    margin-top: 13px;
}

.submitButton.loading {
    color: transparent;
    pointer-events: none;
}

.submitButton__loader {
    position: absolute !important;
    top: 9px !important;
    left: 50% !important;
    margin-left: -40px !important;
}
