.tab {
    height: 100%;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    overflow: hidden;
}

.tab.scrollable {
    overflow: auto;
}
