.AppCalendar-container {
    display: flex;
    flex-direction: column;
    padding-bottom: 42px;
    height: 100%;
    width: 100%;
}

.AppCalendar-calendar-container {
    width: 100%;
    height: fit-content;
}

.AppCalendar-header {
    width: 100%;
    height: 40px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    background-color: #ffffff;
}

.AppCalendar-header-title {
    font-weight: bold;
    color: cadetblue;
}

.AppCalendar-navButton {
    display: flex;
    width: 25%;
    height: 100%;
    align-items: center;
    justify-content: center;
    color: cadetblue;
}

.AppCalendar-navButton-icon {
    font-size: x-large;
}

.AppCalendar-daysRuler {
    display: flex;
    flex-direction: row;
    padding-bottom: 2px;
    padding-top: 2px;
}

.AppCalendar-daysRulerCell-container {
    display: flex;
    width: calc(100% / 7);
    align-items: center;
    justify-content: center;
}

.AppCalendar-daysRulerCell-text {
    font-size: 11px;
    color: #888888;
}

.AppCalendar-calendar {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    background-color: #f7f7f7;
}

.AppCalendar-week {
    display: flex;
    flex-direction: row;
}

.AppCalendar-cell-container {
    display: flex;
    flex-direction: column;
    width: 14.285714285714285%;
    height: 52px;
    padding-top: 7px;
    align-items: center;
    border-top: 1px solid lightgrey;
}

.AppCalendar-cell-container.outOfMonth {
    border-top: none;
}

.AppCalendar-cell-text {
    width: 30px;
    height: 30px;
    text-align: center;
    line-height: 30px;
    border-radius: 50%;
    font-size: medium;
}

.AppCalendar-cell-text.today {
    background-color: cadetblue;
}

.AppCalendar-cell-text.selected {
    background-color: #cccccc;
}

.AppCalendar-cell-text.today.selected {
    background-color: cadetblue;
}

.AppCalendar-cell-eventsIndication {
    height: 4px;
    width: 18px;
    background-color: cadetblue;
    border-radius: 3px;
    display: inline-block;
}

.AppCalendar-dayDetails-container {
    border-top: 1px solid lightgrey;
    overflow-y: scroll;
    background-color: #f7f7f7;
    margin-bottom: 10px;
    height: 100%;
}

.AppCalendar-dayDetails-titleContainer {
    padding: 8px 8px;
    background-color: white;
    border-bottom: 1px solid lightgrey;
}

.AppCalendar-dayDetails-title {
    font-weight: bold;
    font-size: large;
    color: cadetblue;
}

.AppCalendar-dayDetails-body-container {
    overflow-y: scroll;
    padding: 10px;
}

.AppCalendar-eventCell-container {
    height: fit-content;
    margin-bottom: 10px;
    padding: 10px 15px;
    border-radius: 10px;
    background-color: rgba(0, 0, 0, 0.08);
    overflow: auto;
    font-size: var(--base-font-size);
}

.AppCalendar-eventCell-title {
    font-size: 0.9375em;
}

.AppCalendar-eventCell-title-time {
    font-size: 14px;
    color: rgba(0, 0, 0, 0.7);
    margin-right: 0.5em;
}

.AppCalendar-eventCell-body {
    height: fit-content;
    margin-top: 3px;
    font-size: 14px;
}

.AppCalendar-eventCell-body-noEvents {
    text-align: center;
    font-weight: bold;
    margin-top: 30px;
}
