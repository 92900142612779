.screen {
    --animation-duration: 0.5s;

    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: var(--zindex-shutdown);
    background-color: #000;
    overflow: hidden;
    opacity: 0;
    transition: opacity var(--animation-duration);
}

.screen.entered {
    opacity: 1;
}

.inner {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 100%;
    height: 100%;
    transform: translate(-50%, -50%);
    background-color: #fff;
}

.entered > .inner {
    animation: shutDown ease-in var(--animation-duration);
    animation-iteration-count: 1;
    animation-fill-mode: both;
}

@keyframes shutDown {
    50% {
        height: 2px;
        background: "#ffffff";
    }
    100% {
        width: 0;
        height: 0;
    }
}
