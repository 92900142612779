.browser {
    width: 100%;
    height: 100%;
    font-size: var(--base-font-size);
    padding-bottom: 50px;
    display: flex;
    flex-direction: column;
}

.search {
    width: 100%;
    padding: 10px;
    flex: 0;
}

.search__input {
    width: 100%;
    height: 40px;
    line-height: 40px;
    background-color: #ddd;
    border: none;
    border-radius: 25px;
    margin: 0;
    padding: 0 30px;
}

.history__item {
    width: 100%;
    padding: 0.5em 1em;
    color: #333;
    overflow: hidden;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    overflow: hidden;
}

.history__item__text,
.history__item__text > p {
    margin: 0;
    width: 100%;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
}

.history__item__icon {
    color: #666;
    margin-right: 12px;
    font-size: 0.875em;
    flex-shrink: 0;
    flex-grow: 0;
}

.history__item__cached {
    font-size: 12px;
    color: #c40000;
    font-style: italic;
    margin-left: 0.5em;
    line-height: 16px;
}

.history__more {
    text-align: center;
    font-style: italic;
    color: #666;
    font-size: 14px;
    margin-top: 5px;
}

.offline {
    padding: 2em;
    color: #333;
}

.offline__title {
    font-weight: normal;
}

.offline__text {
    margin-top: 1em;
    font-size: 1em;
    line-height: 1.3;
}

.cached {
    position: relative;
    flex-grow: 0;
    flex-shrink: 1;
    overflow: auto;
}

.cached__note {
    position: absolute;
    top: 0;
    left: 25%;
    width: 50%;
    text-align: center;
    background-color: rgba(0, 0, 0, 0.05);
    border-radius: 5px;
    padding: 5px 0;
    color: #666;
    font-size: 14px;
    font-style: italic;
}

.cached__image {
    width: 100%;
}
