.AppFolder {
    height: 100%;
    padding-bottom: 42px;

    background-color: #1e2042;
    background-image: linear-gradient(#000, #1e2042, #30325f);
    background-size: cover;
    background-position: center center;
    font-size: var(--base-font-size);
}

.AppFolder.bgImage {
    background-repeat: no-repeat;
}

.AppFolder-folder-title {
    color: white;
    padding: 20px 20px 0;
    font-size: 1em;
    text-align: center;
    font-weight: bold;
}

.AppFolder-folder-items {
    width: 100%;
    padding: 10px 5px;
}

.AppFolder-folder-items.grid {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
}

.AppFolder-folder-item {
    text-decoration: none;
}

.AppFolder-folder-item.grid {
    text-align: center;
    padding: 20px 5px;
}

.AppFolder-folder-item.list {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 5px;
    background-color: rgba(255, 255, 255, 0.8);
    border-radius: 5px;
    margin-bottom: 5px;
}

.AppFolder-folder-item-icon-wrapper {
    display: inline-block;
    width: 50px;
    height: 50px;
    border-radius: 18px;
    overflow: hidden;
}

.list .AppFolder-folder-item-icon-wrapper {
    width: 30px;
    height: 30px;
    border-radius: 5px;
    margin-right: 10px;
}

.AppFolder-folder-item-icon {
    width: 100%;
    height: 100%;
}

.AppFolder-folder-item-name {
    font-size: 0.9em;
    text-decoration: none;
    overflow-wrap: anywhere;
}

.grid .AppFolder-folder-item-name {
    color: white;
    text-shadow: 0px 0px 2px #333;
    margin-top: 3px;
}

.list .AppFolder-folder-item-name {
    color: #333;
}

/***************************************/

.AppFolder-file {
    width: 100%;
    height: 100%;
    position: relative;

    display: flex;
    justify-content: center;
    align-items: center;

    overflow: hidden;
    padding-top: 40px;
}

.AppFolder-file-top-bar {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    color: white;
    padding: 10px;
    z-index: 1;
    display: flex;
    flex-direction: row;
    align-items: center;
}

.AppFolder-file-back-button {
    color: white;
    padding: 0 10px;
    flex-shrink: 0;
    flex-grow: 0;
}

.AppFolder-file-image {
    width: 100%;
    height: 100%;
    display: block;
}

.AppFolder-file-text {
    width: 90%;
    height: 100%;
    display: block;
    overflow: auto;
    font-size: 0.9em;
    line-height: 1.5;
    color: #fff;
    padding: 20px;
    background-color: rgba(0, 0, 0, 0.8);
    backdrop-filter: blur(5px);
    border-top-right-radius: 10px;
    border-top-left-radius: 10px;
}

.AppFolder-file-text > p:first-child {
    margin-top: 0;
}

.AppFolder-file-text > p:last-child {
    margin-bottom: 0;
}
