.notifications {
    position: absolute;
    top: 20px;
    left: 0;
    right: 0;
    width: 100%;
    padding: 3px 5px;
}

.notification {
    background-color: rgba(220, 220, 220, 0.8);
    backdrop-filter: blur(10px);
    border-radius: 10px;
    padding: 15px 10px;
}

.notification__header {
    color: #666;
    font-size: 13px;
    position: relative;
}

.notification__icon {
    width: 17px;
    height: 17px;
    display: inline-block;
    vertical-align: top;
    margin-right: 5px;
    margin-top: -2px;
}

.notification__app {
    text-transform: uppercase;
}

.notification__time {
    position: absolute;
    bottom: 0;
    right: 0;
    font-size: 12px;
}

.notification__text {
    font-size: 14px;
    margin-top: 8px;
    color: #333;
    padding: 0 1px;
}
