.ChatList {
    height: 100%;
    width: 100%;
    position: relative;
    font-size: var(--base-font-size);
}

.ChatList-title {
    display: none;
    font-size: 1.25em;
    font-weight: bold;
    padding: 10px 10px 0 10px;
    color: var(--primary-title);
}

.mobile .ChatList-title {
    display: block;
}

.ChatList-no-chats {
    text-align: center;
    font-size: 20px;
    color: var(--gray-500);
    padding: 30px 10px;
}

.ChatListItem {
    height: 80px;
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
}

.ChatListItem:hover {
    background-color: #fafafa;
}

.ChatListItem.active {
    background-color: #eeeeee;
}

.ChatListItem-picture,
.ChatListItem-icon {
    width: 40px;
    height: 40px;
    border-radius: 20px;
    flex-grow: 0;
    flex-shrink: 0;
    margin: 0 10px;
}

.ChatListItem-picture {
    background-size: cover;
    background-position: center;
}

.ChatListItem-icon {
    font-size: 40px;
    line-height: 40px;
    text-align: center;
    background-color: rgba(0, 0, 0, 0.1);
    overflow: hidden;
    padding-top: 7px;
    color: rgba(255, 255, 255, 0.8);
}

.ChatListItem-details {
    height: 100%;
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    border-bottom: 1px solid #eeeeee;
    min-width: 0; /* https://css-tricks.com/flexbox-truncated-text/ */
}

.ChatListItem-title {
    position: relative;
    color: var(--secondary-stronger);
}

.ChatListItem-time {
    position: absolute;
    bottom: 0;
    right: 0;
    padding-right: 15px;

    overflow: hidden;
    color: var(--secondary-lighter);
    font-size: 0.75em;
    line-height: 1.25em;
    white-space: nowrap;
    text-overflow: ellipsis;
}

.ChatListItem-status {
    position: relative;
    overflow: hidden;
    color: var(--secondary-stronger);
    font-size: 0.875em;
    line-height: 1.5em;
    white-space: nowrap;
    text-overflow: ellipsis;
    padding-right: 36px;
    margin-top: 3px;
}

.ChatListItem-status.typing {
    color: var(--unread-marker-background);
}

.ChatListItem-status.deleted {
    color: #666;
    font-style: italic;
}

.ChatListItem-status-picture,
.ChatListItem-status-audio {
    color: var(--secondary);
}

.ChatListItem-status-icon {
    vertical-align: text-top;
    margin-right: 0.3em;
    opacity: 0.8;
}

.ChatListItem-unread {
    position: absolute;
    bottom: 0;
    right: 0;
    padding-right: 15px;

    color: white;
    background-color: var(--unread-marker-background);
    font-size: 12px;
    line-height: 1em;
    min-height: 1em;
    border-radius: 1.1em;
    text-align: center;
    padding: 0.3em 0.6em;
    display: inline-block;
    margin-right: 15px;
}
