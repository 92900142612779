.appEditor {
    height: 100%;
    margin-top: 20px;
    overflow: auto;
}

.appEditor__empty {
    font-size: 2em;
    color: #999;
    padding: 20px 0 0 15px;
}

.appEditorSettings {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    margin-top: 10px;
}

.appEditorSettings > * {
    margin-right: 10px !important;
}

.appEditorSettings__icon {
    width: 35px;
    height: 35px;
    border-radius: 10px;
    display: inline-block;
    vertical-align: top;
}
