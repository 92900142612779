:root {
    --font-family-display: "Bungee", cursive;

    --zindex-splash-screen: 1100;
    --zindex-alert: 1010;
    --zindex-loading-message: 1000;
    --zindex-modal: 100;
    --zindex-reward: 100;
    --zindex-signup-popup: 95;
    --zindex-shutdown: 90;
    --zindex-broken-screen-effect: 80;
    --zindex-note: 70;
    --zindex-tutorial: 30;
    --zindex-phone-footer: 20;
}

* {
    box-sizing: border-box;
}

*:not(input):not(textarea) {
    user-select: none;
}

*:focus {
    outline: none;
}

a {
    text-decoration: none;
}

html {
    -webkit-tap-highlight-color: transparent;
}

html,
body,
#root {
    height: 100%;
    width: 100%;
}

body {
    margin: 0;
    font-family: "Roboto", sans-serif;
    /*
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto",
        "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
        "Helvetica Neue", sans-serif;*/
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    overscroll-behavior: none;
    color: #1e1e1e;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
        monospace;
}

textarea {
    resize: none;
}

[class^="icon-"]:before,
[class*=" icon-"]:before {
    margin-left: 0;
    margin-right: 0;
}

/* Hide scrollbar for IE, Edge and Firefox */
.no-scrollbar {
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
}

/* Hide scrollbar for Chrome, Safari and Opera */
.no-scrollbar::-webkit-scrollbar {
    display: none;
}
