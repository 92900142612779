:root {
    --border-stronger: rgba(0, 0, 0, 0.08);
    --bubble-meta: rgba(0, 0, 0, 0.45);
    --compose-input-background: #fff;
    --compose-input-border: #fff;
    --gray-500: grey;
    --icon: #06d755;
    --icon-disabled: #aaa;
    --icon-error: #e40000;
    --outgoing-background: #dcf8c6;
    --panel-header-background: #ededed;
    --primary-strong: #000;
    --primary-title: #525252;
    --secondary-lighter: rgba(0, 0, 0, 0.45);
    --secondary-stronger: rgba(0, 0, 0, 0.8);
    --secondary: rgba(0, 0, 0, 0.6);
    --shadow-rgb: 0, 0, 0;
    --unread-marker-background: #06d755;
    --chat-system-background: rgba(240, 240, 240, 0.9);
    --chat-system-text: rgba(0, 0, 0, 0.9);
}

.Chat {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    position: relative;
}

.ChatWelcome {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    padding: 30px;
    text-align: center;
}

.ChatWelcome-title {
    font-size: 30px;
    font-weight: bold;
    color: var(--primary-title);
}

.ChatWelcome-subtitle {
    font-size: 20px;
    color: var(--gray-500);
}

.ChatHeader {
    width: 100%;
    flex-grow: 0;
    flex-shrink: 0;
    position: relative;

    background-color: var(--panel-header-background);
    padding: 10px 16px 10px 0;
    display: flex;
    flex-direction: row;
    align-items: center;
}

.ChatHeader-back {
    padding: 8px;
}

.ChatHeader-picture {
    width: 40px;
    height: 40px;
    border-radius: 20px;
    background-size: cover;
    background-position: center;
    flex-grow: 0;
    flex-shrink: 0;
}

.ChatHeader-icon {
    width: 40px;
    height: 40px;
    font-size: 40px;
    line-height: 40px;
    text-align: center;
    border-radius: 20px;
    flex-grow: 0;
    flex-shrink: 0;
    background-color: rgba(0, 0, 0, 0.1);
    overflow: hidden;
    padding-top: 7px;
    color: rgba(255, 255, 255, 0.8);
}

.ChatHeader-details {
    padding-left: 15px;
    font-size: var(--base-font-size);
}

.ChatHeader-title {
    font-weight: bold;
    color: var(--primary-strong);
    font-size: 1em;
    line-height: 1.3125em;
}

.ChatHeader-status {
    font-size: 13px;
    line-height: 20px;
    color: var(--secondary);
}

/*************************************/

.ChatMessagesWrapper {
    flex-grow: 1;
    overflow-y: scroll;
    overflow-x: auto;
    position: relative;
    background: url("../../../../images/apps/chat/bg.jpg") top left;
    background-size: 100%;
}

.ChatMessages {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    padding: 20px 5%;
    min-height: 100%;
    position: relative;
}

.ChatMessagesDate {
    align-self: center;
    margin-top: 12px;

    border-radius: 7.5px;
    background-color: white;
    padding: 7px 15px;
    line-height: 1;

    font-size: var(--base-font-size);
    text-transform: uppercase;
    color: rgba(0, 0, 0, 0.8);
    opacity: 0.8;
}

/*************************************/

.ChatFooter {
    width: 100%;
    flex-grow: 0;
    flex-shrink: 0;
    position: relative;

    background-color: var(--panel-header-background);
    padding: 10px 16px;
    display: flex;
    flex-direction: row;
    align-items: center;
}

.ChatFooter-input {
    padding: 9px 12px 11px;
    background-color: var(--compose-input-background);
    border: 1px solid var(--compose-input-border);
    border-radius: 21px;
    flex-grow: 1;
    font-size: 15px;
    line-height: 20px;
}

.ChatFooter-send-button {
    font-size: 24px;
    line-height: 24px;
    color: var(--icon);
    cursor: pointer;
    padding-left: 8px;
    transition: color 0.2s;
}

.ChatFooter-send-button.disabled {
    pointer-events: none;
    color: var(--icon-disabled);
}

/*************************************/

.ChatImageView {
    height: 100%;
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    background-color: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
}

.ChatImageView-close {
    position: absolute;
    top: 0;
    left: 0;
    color: #333;
    font-size: 20px;
    padding: 10px;
    z-index: 1;
}
