.welcomeScreen {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    padding: 10px;
    background-color: #fff;
}

.welcomeScreen__title {
    font-family: var(--font-family-display);
    font-size: 40px;
    margin-bottom: 20px;
}

.welcomeScreen__title__studio {
    background: linear-gradient(
        0deg,
        rgba(37, 182, 222, 1) 0%,
        rgba(171, 56, 180, 1) 100%
    );
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

.welcomeScreen__title__beta {
    font-size: 14px;
    margin-left: 0.3em;
    font-family: "Roboto", sans-serif;
    font-weight: bold;
    opacity: 0.8;
}

.welcomeScreen__loading {
    text-align: center;
    margin-top: 10px;
}

.welcomeScreen__loading__text {
    margin-top: 10px;
}

.welcomeScreen__denied {
    max-width: 400px;
    text-align: center;
}

.welcomeScreen__denied > p:first-child {
    margin-top: 0;
}

.welcomeScreen__legal {
    font-size: 12px;
    color: #666;
    line-height: 1.3;
}

.welcomeScreen__legal > a {
    color: #666;
    text-decoration: underline;
}

.storyGrid {
    width: 80%;
    overflow: auto;
    text-align: center;
}

.storyGrid__item {
    display: inline-block;
    vertical-align: top;
    position: relative;
    width: 180px;
    height: 180px;
    margin: 5px;
    border: 2px solid #999;
    border-radius: 5px;
    text-align: center;
    padding: 10px;
    opacity: 0.8;
    transition: opacity 0.1s;
    text-align: left;
}

.storyGrid__item:hover {
    opacity: 1;
}

.storyGrid__item.new {
    border-style: dashed;
    display: inline-flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.storyGrid__item__contents {
    color: #1e1e1e;
    display: block;
    width: 100%;
    height: 100%;
}

.storyGrid__item__title {
    font-weight: bold;
    font-size: 20px;
}

.storyGrid__item__created {
    font-size: 14px;
    font-style: italic;
    opacity: 0.7;
    margin-top: 1em;
}

.storyGrid__item__plus {
    font-size: 40px;
    line-height: 1;
    display: block;
}

.storyGrid__item__menu {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    padding: 5px;
    background-color: #fff;
    border-top: 1px solid #999;
    font-size: 18px;
    text-align: right;
    border-bottom-right-radius: 5px;
    border-bottom-left-radius: 5px;
    opacity: 0;
    transition: opacity 0.1s;
}

.storyGrid__item:hover .storyGrid__item__menu {
    opacity: 1;
}

.storyGrid__item__menu__action {
    display: inline-block;
    width: 1em;
    height: 1em;
    opacity: 0.8;
    transition: opacity 0.1s;
    cursor: pointer;
}

.storyGrid__item__menu__action:hover {
    opacity: 1;
}
