.form {
    margin-top: 0;
    display: block;
    position: relative;
    width: 100%;
}

.input {
    width: 100%;
}

.visibleIcon {
    height: 1.3em;
    position: absolute;
    transform: translateY(-50%);
    top: 50%;
    right: 1em;
    color: #757575;
}
