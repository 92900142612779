.screen {
    width: 100%;
    height: 100%;
    position: relative;
    padding-top: 20px;
    box-shadow: -10px 0 25px -6px rgba(0, 0, 0, 0.7);
}

.content {
    width: 100%;
    height: 100%;
}

.content.menuOpen {
    pointer-events: none;
}
