.app {
    height: 100%;
    width: 100%;
    padding-bottom: 42px;
    background-image: linear-gradient(to bottom, #7f00ff 0%, #e100ff 100%);
}

.title,
.subtitle {
    color: #fffec6;
    text-shadow: 0 1px 1px #6600cd;
    font-family: "Roboto Condensed", sans-serif;
}

.title {
    font-size: 30px;
    font-weight: bold;
    text-align: center;
}

.subtitle {
    font-size: 16px;
    opacity: 0.9;
    margin-top: 1em;
    text-align: center;
}

.subtitle > p {
    margin: 0;
}

.index {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
}

.index__header {
    padding: 30px 0;
    flex: 0;
}

.index__items {
    overflow: auto;
    padding: 0 10px 10px;
    height: 100%;
}

.indexItem {
    width: 100%;
    border-radius: 10px;
    padding: 13px 20px;
    background-color: rgba(255, 255, 255, 0.6);
    color: #333;
    display: flex;
    flex-direction: row;
    cursor: pointer;
    margin-top: 5px;
}

.indexItem__texts {
    flex-grow: 1;
    line-height: 1;
}

.indexItem__title {
    font-size: 15px;
    font-weight: bold;
}

.indexItem__subtitle,
.indexItem__status {
    font-size: 14px;
    margin-top: 3px;
}

.home,
.ordered,
.tracker {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 5% 10%;
}

.tracker {
    padding-right: 0;
    padding-left: 0;
}

.home__hero {
    display: block;
    width: 50%;
    height: auto;
    margin-top: 50px;
}

.home__keyboardWrapper {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;

    opacity: 0;
    visibility: hidden;
    transition: visibility 0s linear 0.1s, opacity 0.1s linear;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 20px;

    color: white;
    text-shadow: 0 0 2px black;
    background-color: rgba(0, 0, 0, 0.5);
    backdrop-filter: blur(10px);
}

.home__keyboardWrapper:global(.visible) {
    opacity: 1;
    visibility: visible;
    transition: visibility 0s linear 0s, opacity 0.1s linear;
}

.ordered__hero {
    display: block;
    width: 40%;
    height: auto;
    margin-top: 50px;
}

.tracker__map {
    width: 100%;
    height: 0;
    padding-top: 100%;
    margin-top: 20px;
    background-size: cover;
    background-repeat: no-repeat;
    position: relative;
}

.tracker__map_copyright {
    position: absolute;
    bottom: 0;
    right: 0;
    font-size: 11px;
    background-color: rgba(255, 255, 255, 0.6);
    padding: 3px;
}

.tracker__map_copyright_link {
    color: #333;
    text-decoration: underline;
}

.tracker__drone {
    width: 50px;
    height: 50px;
    margin-top: -25px;
    margin-left: -25px;
    position: absolute;
    transition: transform 1s linear;
}

.tracker__finished {
    height: 150px;
    width: auto;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.tracker .subtitle {
    padding: 0 20px;
    height: 40px;
}

.button {
    display: inline-block;
    border-radius: 8px;
    box-shadow: 0 8px 0 #c5376d, 0 15px 20px rgba(0, 0, 0, 0.35);
    transition: box-shadow 0.1s ease-in-out;
    font-size: 20px;
    color: #fff;
    margin-top: 50px;
}

.inner {
    display: inline-block;
    padding: 20px 30px;
    background-color: #ec528d;
    background-image: -webkit-gradient(
        linear,
        0% 0%,
        0% 100%,
        from(hsla(338, 90%, 80%, 0.8)),
        to(hsla(338, 90%, 70%, 0.2))
    );
    border-radius: 8px;
    box-shadow: inset 0 -1px 1px rgba(255, 255, 255, 0.15);
    font-family: "Roboto Condensed", sans-serif;
    line-height: 1;
    text-shadow: 0 -1px 1px rgba(175, 49, 95, 0.7);
    transition: background-color 0.2s ease-in-out, transform 0.1s ease-in-out;
}
/*
.button:hover .inner {
    background-color: #ec6a9c;
    text-shadow: 0 -1px 1px rgba(175, 49, 95, 0.9),
        0 0 5px rgba(255, 255, 255, 0.8);
}
*/
.button:active {
    box-shadow: 0 8px 0 #c5376d, 0 12px 10px rgba(0, 0, 0, 0.3);
}

.button:active .inner {
    transform: translate(0, 4px);
    text-shadow: 0 -1px 1px rgba(175, 49, 95, 0.9),
        0 0 5px rgba(255, 255, 255, 0.8);
}
