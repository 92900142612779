.LoginButton {
    display: block;
    width: 100%;
    padding: 10px 15px;
    text-align: center;
    color: white;
    margin-top: 10px;
    cursor: pointer;
    border-radius: 10px;
}

.LoginButton.inline {
    display: inline-block;
    width: auto;
    margin: 0 5px;
    border-radius: 20px;
}

.LoginButton.loading {
    pointer-events: none;
    opacity: 0.8;
}

.LoginButton.facebook {
    background-color: #3b5998;
}

.LoginButton.google {
    background-color: #dd4b39;
}

.LoginButton.apple {
    background-color: #000;
}

.LoginButton-icon {
    vertical-align: top;
    margin-right: 0.4em;
    line-height: 1;
}
