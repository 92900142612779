.LockScreen {
    display: block;
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;

    background-color: white;
    background-size: cover;
    background-position: center center;
    background-repeat: no-repeat;

    opacity: 1;
    transition: visibility 0s linear 0s, opacity 0.5s linear;
}

.LockScreen.unlocked {
    opacity: 0;
    pointer-events: none;
    visibility: hidden;
    transition: visibility 0s linear 0.5s, opacity 0.5s linear;
}

.LockScreen-default {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;

    opacity: 0;
    visibility: hidden;
    transition: visibility 0s linear 0.1s, opacity 0.1s linear;

    padding: 100px 30px;

    color: white;
    text-shadow: 0 0 2px black;
}

.LockScreen-default.visible {
    opacity: 1;
    visibility: visible;
    transition: visibility 0s linear 0s, opacity 0.1s linear;
}

.LockScreen-default-time {
    font-size: 40px;
    text-shadow: 0 0 4px black;
}

.LockScreen-default-date {
    font-size: 28px;
    text-shadow: 0 0 4px black;
}

.LockScreen-default-cta {
    width: 100%;
    position: absolute;
    bottom: 100px;
    left: 0;
    text-align: center;
}

.LockScreen-keyboard-wrapper {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;

    opacity: 0;
    visibility: hidden;
    transition: visibility 0s linear 0.1s, opacity 0.1s linear;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 20px;

    color: white;
    text-shadow: 0 0 2px black;
    background-color: rgba(0, 0, 0, 0.5);
    backdrop-filter: blur(10px);
}

.LockScreen-keyboard-wrapper.visible {
    opacity: 1;
    visibility: visible;
    transition: visibility 0s linear 0s, opacity 0.1s linear;
}
