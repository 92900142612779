.alert {
    position: absolute;
    top: 0;
    left: 0;
    z-index: var(--zindex-alert);
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.3);
    backdrop-filter: blur(10px);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.content {
    width: 90%;
    max-height: 90%;
    max-width: 400px;
    background-color: white;
    border-radius: 20px;
    padding: 30px 0;
    text-align: center;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    overflow: hidden;
}

.scroller {
    overflow: auto;
    padding: 0 30px;
}

.title,
.text {
    font-size: 16px;
    text-align: left;
    width: 100%;
}

.title {
    font-weight: bold;
}

.buttons {
    width: 100%;
    margin-top: 20px;
    text-align: right;
}

.button {
    font-size: 16px;
    color: var(--color-text-primary);
    padding: 10px 0 10px 20px;
    display: inline-block;
    cursor: pointer;
}

.button.secondary {
    color: #999;
}
