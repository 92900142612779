.list {
    display: flex;
    overflow: auto;
}

.list.horizontal {
    flex-direction: row;
}

.list.vertical {
    flex-direction: column;
}

.draggable {
    user-select: none;
}
