.appSelector {
    flex-shrink: 0;
    flex-grow: 0;
    display: flex;
    flex-direction: row;
    width: 100%;
    margin-top: 10px;
}

.appSelector__app {
    position: relative;
    width: 70px;
    max-height: 100px;
    text-align: center;
    border-radius: 5px;
    cursor: pointer;
    padding: 5px 5px 5px 0;
    flex-grow: 0;
    flex-shrink: 0;
}

.appSelector__app.selected {
    background-color: #feffb8;
}

.appSelector__app__icon,
.appSelector__addButton__icon {
    width: 50px;
    height: 50px;
    border-radius: 18px;
    display: inline-block;
    vertical-align: top;
}

.appSelector__app__title {
    font-size: 13px;
    line-height: 1;
    text-align: center;
    margin-top: 2px;
}

.appSelector__app__delete {
    position: absolute;
    top: 0;
    right: 2px;
    font-size: 14px;
    line-height: 1;
    background-color: #000;
    color: #fff;
    border-radius: 6px;
    padding: 3px;
}

.appSelector__addButton__icon {
    border: 2px dashed #999;
    font-size: 26px;
    line-height: 46px;
    color: #999;
    opacity: 0.5;
    transition: opacity 0.1s;
}

.appSelector__addButton__icon:hover {
    opacity: 1;
}
