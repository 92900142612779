.AppPhotos {
    width: 100%;
    height: 100%;

    font-size: var(--base-font-size);
    padding-bottom: 50px;
    overflow: hidden;
}

.AppPhotos-no-photos {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.AppPhotos-no-photos-title {
    font-size: 20px;
    font-weight: bold;
    color: #666;
}

.AppPhotos-index-scroller {
    overflow: auto;
    height: 100%;
}

.AppPhotos-index {
}

.AppPhotos-section-name {
    font-size: 1em;
    padding: 10px 5px 5px 5px;
}

.AppPhotos-photogrid {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    grid-gap: 3px 3px;
}

.AppPhotos-photogrid-thumb {
    position: relative;
    width: 100%;
    height: 100%;
    background: linear-gradient(to bottom, #f4f4f4 0%, #f4f4f4 70%, #eee 100%);
}

.AppPhotos-photogrid-thumb:before {
    content: " ";
    display: block;
    width: 100%;
    padding-top: 100%;
}

.AppPhotos-photogrid-thumb-image {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
}

.AppPhotos-photogrid-thumb-play {
    color: white;
    font-size: 50px;
    line-height: 50px;
    position: absolute;
    top: 50%;
    left: 50%;
    margin-top: -25px;
    margin-left: -25px;
    z-index: 10;
}

.AppPhotos-photo-details {
    position: relative;
    width: 100%;
    height: 100%;
}

.AppPhotos-photo-details-header {
    position: absolute;
    width: 100%;
    z-index: 100;
    display: flex;
}

.AppPhotos-photo-details-header-back-btn {
    font-size: 25px;
    color: #333;
    padding: 5px 10px;
}

.AppPhotos-photo-details .react-transform-component {
    overflow: visible;
}

.AppPhotos-photo-details-photo-wrapper {
    height: 100%;

    display: flex;
    justify-content: center;
    align-items: center;

    overflow: hidden;
}

.AppPhotos-photo-details-photo-info {
    position: absolute;
    bottom: 0;
    width: 100%;
    left: 0;
    text-align: center;
    height: 22px;
    line-height: 22px;
    font-size: 14px;
    font-weight: bold;
    color: white;
    letter-spacing: 1px;
    text-shadow: 0.05em 0 rgba(0, 0, 0, 0.5), 0 0.05em rgba(0, 0, 0, 0.5),
        -0.05em 0 rgba(0, 0, 0, 0.5), 0 -0.05em rgba(0, 0, 0, 0.5),
        -0.05em -0.05em rgba(0, 0, 0, 0.5), -0.05em 0.05em rgba(0, 0, 0, 0.5),
        0.05em -0.05em rgba(0, 0, 0, 0.5), 0.05em 0.05em rgba(0, 0, 0, 0.5);
}

.AppPhotos-photo-details-photo {
    display: block;
    max-width: 100%;
    max-height: 100%;
    object-fit: contain;
}

.AppPhotos-photo-details-video {
    width: 100%;
    height: auto;
}
.AppPhotos-photo-details-photo-zoom-tutorial {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    pointer-events: none;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    opacity: 0;
    transition: opacity 0.1s;
}

.AppPhotos-photo-details-photo-zoom-tutorial.visible {
    opacity: 1;
}

.AppPhotos-photo-details-photo-zoom-tutorial-container {
    color: white;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    animation: hover 2s infinite;
}

@media screen and (max-width: 300px) {
    .AppPhotos-photogrid {
        grid-template-columns: 1fr 1fr 1fr;
    }
}

@keyframes hover {
    from,
    to {
        transform: translate3d(0, 0, 0);
    }

    50% {
        transform: translate3d(-5px, -5px, 0);
    }
}
