.brokenScreen {
    background-size: cover;
    background-position: center center;
    pointer-events: none;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: var(--zindex-broken-screen-effect);
    opacity: 0.8;
}
