.AppFindFriends {
    display: flex;
    flex-direction: column;
    height: 100%;
    padding-bottom: 42px;
}

.AppFindFriends-title {
    font-size: 18px;
    font-weight: bold;
    text-align: center;
    line-height: 40px;
    flex-shrink: 0;
}

.AppFindFriends-map {
    width: 100%;
    height: 200px;
    overflow: hidden;
    background-size: cover;
    background-position: center center;
    border-top: 1px solid #999;
    border-bottom: 1px solid #999;
    flex-shrink: 0;
}

.AppFindFriends-entry {
    display: flex;
    flex-direction: row;
    padding: 10px 10px 0 10px;
}

.AppFindFriends-entry-img {
    width: 50px;
    height: 50px;
    flex-shrink: 0;
    flex-grow: 0;
    border-radius: 25px;
    outline: 0.3px solid rgb(120, 120, 120);
    object-fit: cover;
}

.AppFindFriends-entry-details {
    margin-left: 10px;
    /*padding-bottom: 10px;*/
    border-bottom: 1px solid #eee;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.AppFindFriends-entry:last-child .AppFindFriends-entry-details {
    border-bottom: none;
}

.AppFindFriends-entry-name {
    font-size: 18px;
    color: #333;
}

.AppFindFriends-entry-name.selected {
    font-weight: bold;
}

.AppFindFriends-entry-location {
    font-size: 14px;
    margin: 5px 0;
    color: #999;
}

.AppFindFriends-list {
    overflow: auto;
}
